<template>
  <div class="main-container" ref="cssDataRef">
    <!-- 首页大背景图 -->
    <img class="main-bg" :src="homeBgImg" alt="" />
    <!-- 最新公告栏 -->
    <MainAnnouncement></MainAnnouncement>
    <!-- 重要通知 -->
    <MainNotice></MainNotice>
    <!-- 系统指引 -->
    <MainSystem></MainSystem>
    <!-- <div id="main" v-show="closeFlowFlag">
        <div id="xf" @mouseover="stop()" @mouseleave="start()">
            <div class="xf-title">系统升级改造通知</div>
            <div class="xf-content">本系统于<span style="color: red;">2024年1月3日</span>至<span style="color: red;">2024年1月5日</span>进行网络升级改造。升级改造期间，可能会导致系统服务中断，敬请谅解！</div>
        </div>
        <span @mouseover="stop()" @mouseleave="start()" @click="closeFlowWin" style="color: black;">关闭窗口</span>
    </div> -->
  </div>
</template>

<script>
import MainSystem from './vue/main-system.vue'
import MainNotice from './vue/main-notice.vue'
import MainAnnouncement from './vue/main-announcement.vue'
import { oauthLoginCode } from "@/axios/api/main.js";
import JSEncrypt from "jsencrypt";

export default {
  name: 'Index',
  props: {},
  components: {
    MainSystem,
    MainNotice,
    MainAnnouncement,
  },
  data() {
    return {
      closeFlowFlag: true,
      main: null,
      sy: null,
      speedy: null,
      sx: null,
      speedx: null,
      imgh: null,
      imgw: null,
      winh: null,
      winw: null,
      sobj: null,
      homeBgImg: require('./image/home-bg@2x.png'), // 首页背景图
      options: {
        tenantCode: [],
        systemCode: [],
        entityType: [],
      },
      systemEntity: {
        map: null,
        systemArr: [],
        entityArr: [],
      },
    }
  },
  computed: {},
  watch: {},
  mounted() {
    // const code = this.$route.query.code
    // if(code){
    //   console.log('code',code)
    //   this.userLogin(code)
    // }
    this.initXF()
  },
  beforeDestroy() {
      this.stop()
  },
  methods: {
    closeFlowWin() {
        this.closeFlowFlag = false
    },
    initXF() {
        this.main = document.getElementById('main')
        this.sy = 0
        this.speedy = 1
        this.sx = 0
        this.speedx = 1
        this.imgh = 80
        this.imgw = 200
        this.winh = document.documentElement.clientHeight
        this.winw = document.documentElement.clientWidth
        this.start()
    },
    start() {
            var that = this
            this.sobj = setInterval(function () {
                that.sy += that.speedy
                that.sx += that.speedx
                //y轴运动
                if (that.sy <= 0) {
                    that.speedy = -that.speedy
                }
                if (that.sy >= that.winh - that.imgh) {
                    that.speedy = -that.speedy
                    that.sy = that.winh - that.imgh
                }
                //x轴运动
                if (that.sx <= 0) {
                    that.speedx = -that.speedx
                }
                if (that.sx >= that.winw - that.imgw) {
                    that.speedx = -that.speedx
                    that.sx = that.winw - that.imgw
                }
                that.main.style.top = that.sy + 'px'
                that.main.style.left = that.sx + 'px'
            }, 15)
    },
    stop() {
            clearInterval(this.sobj)
            this.sobj = null
    },
    async userLogin(code) {
      let encryptor = new JSEncrypt();
      let publicKey =
          "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCqPvlpJjq2wOBQnkZ1oiepyX2lKq/LIjuG+C17Fdd+gsYQTUJbVNLgNcBXtymSAgmllpBc1J3U0Ojf07j58olnb7jMTGRlsBsmFEhNY51pv2KfbHjNfnhvI3Fi6eO1bPmfwj4dIPDJP8c9ChFtWfliwVxF8yFryEtMb3HqLa6w5QIDAQAB";
      encryptor.setPublicKey(publicKey);

      this.$b.removeStorage();

      let params = {
        code: code,
        state: 1,
      };
      oauthLoginCode(params)
          .then((res) => {
            console.log(res);
            if(res && res.access_token){
              let userInfo = res;
              this.$store.commit("$setState", {
                key: "userInfo",
                value: userInfo,
              });
              this.$store.commit("$setState", {
                key: "systemEntity",
                value: this.systemEntity,
              });
              this.$store.commit("$setState", {
                key: "options",
                value: this.options,
              });
              localStorage.setItem("token", res.access_token);
              localStorage.setItem("userInfo", JSON.stringify(userInfo));
              localStorage.setItem(
                  "systemEntity",
                  JSON.stringify(this.systemEntity)
              );
              localStorage.setItem("options", JSON.stringify(this.options));
              // 登录信息 更换主体类型/区划-重登录用 TODO 后期需调整 理论不该用账号密码
              localStorage.setItem("reloginInfo", JSON.stringify({
                  username: res.userName,
                  password: '',
                  grant_type: "password",
                  deviceId: Date.now(),
                  tenantCode: res.tenantCode,
                  systemCode: res.systemCode,
                  entityType: res.entityType,
              }));
              this.baseFun(); // 获取平台基础信息
            }
          })
          .catch((err) => {
            console.log(err);
            return;
          });
    },
    async baseFun() {
      await this.$b.createMenuList(); // 只获取当前平台的权限信息
      await this.$b.setTreeList(); //  获取完整资源树
      this.$router.push({
        path: this.$store.state.menu.menuList[0].child[0].route,
      });
      if (this.$route.query.next) {
        this.$router.push({
          path: this.$route.query.next,
        });
      } else {
        if (this.$store.state.menu.routeList.length > 0) {
          this.$router.push({
            path: this.$store.state.menu.routeList[0],
          });
        } else {
          this.$Message.warning("没有权限");
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
#main {
    position: fixed;
    top: 10px;
    left: 10px;
    cursor: pointer;
    z-index: 300;
}
#xf {
    background-image: url('./image/xfc.png');
    width: 370px;
    height: 173px;
    background-repeat: no-repeat;
    background-size: 400px 200px;
    background-color: #fff;
    background-position: -6px -23px;
    padding: 57px 10px 0;
}
.xf-title {
    text-align: center;
    font-size: 20px;
    color: #00386B;
    font-weight: 700;
}
.xf-content {
    margin-top: 10px;
    font-size: 14px;
}
.main-container {
  .main-bg {
    width: 100%;
    min-width: 1200px;
    height: 451px;
    background-size: 100% 100%;
  }
}
</style>
