import { isIEBrowser } from './browser.js'

export const ie_text_ellipsis = (that, className, len) => {
  if (isIEBrowser()) {
    var content_arr = [] //定义一个空数组
    that.$(`.${className}`).each(function() {
      //遍历box内容
      var content = that.$.trim(that.$(this).text()) //去掉前后文空格
      content_arr.push(content) //内容放进数组
    })
    for (var i = 0; i < content_arr.length; i++) {
      //遍历循环数组
      if (content_arr[i].length >= len) {
        // 判断文本长度是否大于预设长度
        const content = content_arr[i].substr(0, len) + '...' //添加省略号并放进box文字内容后面
        that
          .$(`.${className}`)
          .eq(i)
          .text(content)
      } else {
        const content = content_arr[i]
        that
          .$(`.${className}`)
          .eq(i)
          .text(content)
      }
    }
  }
}

export const text_ellipsis = (that, className, len) => {
  var content_arr = [] //定义一个空数组
  that.$(`.${className}`).each(function() {
    //遍历box内容
    var content = that.$.trim(that.$(this).text()) //去掉前后文空格
    content_arr.push(content) //内容放进数组
  })
  for (var i = 0; i < content_arr.length; i++) {
    //遍历循环数组
    if (content_arr[i].length >= len) {
      // 判断文本长度是否大于预设长度
      const content = content_arr[i].substr(0, len) + '...' //添加省略号并放进box文字内容后面
      that
        .$(`.${className}`)
        .eq(i)
        .text(content)
    } else {
      const content = content_arr[i]
      that
        .$(`.${className}`)
        .eq(i)
        .text(content)
    }
  }
}
