/*
 * axios 接口请求函数
 */
import { fetch } from '../fetch'; // 引入fetch.js
import api from '../url'; // 引入url.js
// import qs from 'qs'; // 引入qs

// 首页-最新公告
export function GetProjectNoticeLatest(data) {
  return fetch({
    url: api.GetProjectNoticeLatest,
    method: 'get',
    params: data,
  });
}

// 首页-重要通知
export function GetImportantNotice(data) {
  return fetch({
    url: api.GetImportantNotice,
    method: 'get',
    params: data,
  });
}

// 首页-项目公告列表
export function GetProjectNoticePage(data) {
  return fetch({
    url: api.GetProjectNoticePage,
    method: 'post',
    data: JSON.stringify(data)
  });
}

// 首页-项目公告详情
export function GetProjectNoticeDetail(data) {
  return fetch({
    url: api.GetProjectNoticeDetail,
    method: 'get',
    // data: JSON.stringify(data),
    params: data,
  });
}


// 查询公告通知
export function GetPotalNoticePage(data) {
  return fetch({
    url: api.GetPotalNoticePage,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 查询公告通知详情
export function GetPotalNoticeDetail(params) {
  return fetch({
    url: api.GetPotalNoticeDetail,
    method: 'get',
    params: params,
  })
}
