/**
 * 获取应用跳转的地址前缀
 * @returns
 */
export const getBasePrefixUrl = () => {
    let jumpUrl = 'https://fgj-bid-dev.ceczy.com:7074/'
    if (process.env.VUE_APP_ENV == 'development') {
        console.log('dev')
        jumpUrl = 'https://fgj-bid-dev.ceczy.com:7074/'
    } else if (process.env.VUE_APP_ENV == 'test') {
        console.log('test')
        jumpUrl = 'https://fgj-bid-test.ceczy.com:7075/'
    } else if (process.env.VUE_APP_ENV == 'production') {
        console.log('prod')
        // jumpUrl = 'https://fgj-bid.ceczy.com:9102/'
        jumpUrl = 'https://czytest.whwgzx.com/'
    } else if (process.env.VUE_APP_ENV == 'prd') {
        console.log('prd')
        jumpUrl = 'https://ztb.whwgzx.com:9202/'
    }
    return jumpUrl
}
