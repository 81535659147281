<template>
  <div class="notice-info-container">
    <div class="breadcrumb-info">当前位置 > 重要通知 > 详情</div>
    <div class="module-wrap">
      <!-- 左侧模块菜单 -->
<!--      <LeftMenu></LeftMenu>-->
      <div class="content-wrap">
        <div class="title">
          {{ info.titleName }}
        </div>
        <div class="publish-time">发布时间：{{ info.releaseDate }}</div>
        <div class="line-1px"></div>
        <div class="content" v-html="info.content"></div>
        <div class="file-wrap" v-show="false">
          <span
            v-for="(file, ind) in files"
            :key="ind"
            @click="handleDownFile(file)"
            >{{ file.name }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetPotalNoticeDetail } from '@/axios/api/home.js'
// import LeftMenu from '@/views/index/left-menu/left-menu.vue'

export default {
  name: 'NoticeInfo',
  components: {
    // LeftMenu,
  },
  data() {
    return {
      id: '',
      info: {},
      files: [],
    }
  },

  mounted() {
    this.id = this.$route.query.id || ''
    this.getDetail()
  },

  methods: {
    async getDetail() {
      const res = await GetPotalNoticeDetail({ id: this.id })
      if (res.enclosureUrl) {
        this.files = JSON.parse(res.enclosureUrl)
      }
      this.info = res
    },
    async handleDownFile(file) {
      if (file) {
        const link = document.createElement('a')
        document.body.appendChild(link)
        //_blank表示在新窗口打开链接
        link.style.display = 'none'
        link.target = '_blank'
        link.href = file ? file.url : ''
        link.download = file.name
        link.click()
        document.body.removeChild(link) // 下载完成移除元素
      }
    },
  },
}
</script>

<style lang="less" scoped>
.notice-info-container {
  background: #f8f9fd;
  padding-bottom: 80px;
  .breadcrumb-info {
    width: 1200px;
    height: 66px;
    line-height: 66px !important;
    margin: 0px auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #333333;
  }
  .module-wrap {
    width: 1200px;
    min-height: 752px;
    margin: 0px auto;
    display: flex;
    overflow: hidden;
    .content-wrap {
      flex: 1 1 auto;
      padding: 40px 30px;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      .title {
        margin: 0px auto;
        width: 648px;
        font-size: 24px;
        font-weight: bold;
        line-height: 33px;
        text-align: center;
        color: #333333;
      }
      .publish-time {
        margin: 10px auto 0px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #999999;
      }
      .line-1px {
        margin-top: 21px;
        height: 1px;
        width: 100%;
        background-color: #ccc;
      }
      .content {
        margin-top: 20px;
        font-size: 16px;
        p {
          margin-bottom: 20px;
        }
      }
      .file-wrap {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        span {
          display: inline-block;
          color: #4184f4;
          line-height: 30px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
