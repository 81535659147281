<template>
  <div class="main-notice">
    <div
      class="title-wrap"
      data-aos="fade-up"
      data-aos-easing="ease"
      data-aos-offset="0"
      data-aos-delay="200"
    >
      <span class="txt">重要通知</span>
      <span class="underline"></span>
    </div>
    <div
      class="content-wrap"
      data-aos="fade-up"
      data-aos-easing="ease"
      data-aos-offset="0"
      data-aos-delay="200"
    >
      <div class="lt-news">
        <div
          class="item"
          v-for="(notice, ind) in noticeList"
          :key="ind"
          @click="goAnnouncementDetal(notice)"
          @mouseenter="handleMourseChoosePic(ind)"
          @mouseleave="handleMourseChoosePic(0)"
        >
          <div class="t-wrap">
            <div class="time">
              <span class="year">{{ notice.year }}</span>
              <span class="month-day">{{ notice.monthDay }}</span>
            </div>
            <i class="el-icon-plus"></i>
          </div>
          <div class="home-important-notice-desc">
            {{ notice.titleName }}
          </div>
        </div>
      </div>
      <div class="rt-previews">
        <Carousel
          :value="0"
          loop
          arrow="never"
          trigger="hover"
          easing="swing"
          radius-dot
        >
          <CarouselItem v-for="(item, i) in noticeBannerList" :key="i">
            <img class="notice-banner-pic" :src="item.url" />
          </CarouselItem>
        </Carousel>
      </div>
    </div>
    <div class="btn-wrap">
      <Button
        shape="circle"
        type="success"
        ghost
        data-aos="fade-down"
        data-aos-easing="ease"
        data-aos-offset="0"
        data-aos-delay="150"
        @click="goAnnouncementList"
      >
        查看更多
        <!-- <Icon type="md-arrow-forward" /> -->
        <i class="el-icon-right"></i>
      </Button>
    </div>
  </div>
</template>

<script>
import { GetImportantNotice } from '@/axios/api/home.js'
import { isIEBrowser } from '@/utils/browser.js'
import { text_ellipsis } from '@/utils/compatibilityIE.js'

export default {
  name: 'MainNotice',

  data() {
    return {
      defaultPic: require('../image/news-branner-1.png'),
      //重要通知轮播参数
      noticeBannerList: [],
      noticeList: [],
    }
  },
  async mounted() {
    await this.getDetail()
    // 兼容IE
    this.compatibleIE()
  },

  methods: {
    async getDetail() {
      const list = await GetImportantNotice({
        number: 3,
      })
      if (list.length > 0) {
        list.forEach((one) => {
          if (one.releaseDate) {
            one.year = one.releaseDate.substring(0, 4)
            one.monthDay = one.releaseDate.substring(
              5,
              one.releaseDate.length
            )
          }
          if (one.enclosureUrl) {
            one.enclosureUrl = JSON.parse(one.enclosureUrl)
          }
        })
        if (list[0].enclosureUrl) {
          this.noticeBannerList = list[0].enclosureUrl || []
        } else {
          this.noticeBannerList = [
            {
              url: this.defaultPic,
            },
          ]
        }
      } else {
        this.noticeBannerList = [
          {
            url: this.defaultPic,
          },
        ]
      }
      this.noticeList = list
    },
    handleMourseChoosePic(ind) {
      this.noticeBannerList = this.noticeList[ind].enclosureUrl
    },
    // 兼容IE
    compatibleIE() {
      // 兼容ie，文字超行省略...
      if (isIEBrowser()) {
        text_ellipsis(this, 'home-important-notice-desc', 45)
      } else {
        text_ellipsis(this, 'home-important-notice-desc', 45)
      }
    },
    goAnnouncementDetal(row) {
      this.$router.push({
        path: '/notice-info',
        query: { id: row.id },
      })
    },
    goAnnouncementList() {
      this.$router.push({
        path: '/notice',
        query: { name: '重要通知' },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.main-notice {
  width: 100%;
  min-width: 1200px;
  background: #fff;
  padding: 60px 0px;
  .title-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .txt {
      font-size: 30px;
      color: #333;
      font-weight: bold;
      height: 42px;
      line-height: 42px;
    }
    .underline {
      margin-top: 5px;
      width: 60px;
      height: 2px;
      background-color: #4184f4;
    }
  }
  .content-wrap {
    width: 1200px;
    margin: 42px auto 39px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .lt-news {
      width: 561px;
      height: 349px;
      border: 1px solid #cccccc;
      box-shadow: 0px 3px 6px rgba(0, 93, 255, 0.5);
      font-weight: 500;
      font-size: 18px;
      padding: 8px 18px 8px 37px;
      .item {
        height: 111px;
        padding: 29px 0px;
        border-bottom: 1px solid #cccccc;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        cursor: pointer;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          .t-wrap {
            background: #4184f4;
            .time {
              .year {
                color: #fff;
              }
              .month-day {
                color: #fff;
              }
            }
            .el-icon-plus {
              display: inline-block;
            }
          }
          .home-important-notice-desc {
            color: #4184f4;
          }
        }
        .t-wrap {
          position: absolute;
          left: -154px;
          z-index: 1;
          width: 146px;
          padding: 0px 19px 0px 18px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .time {
            display: flex;
            flex-direction: column;
            .year {
              font-size: 26px;
              font-weight: bold;
              line-height: 37px;
              color: #333333;
              margin-bottom: 1px;
            }
            .month-day {
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              color: #666666;
            }
          }
          .el-icon-plus {
            font-size: 20px;
            color: #fff;
            display: none;
          }
        }
        .home-important-notice-desc {
          font-weight: 500;
          line-height: 25px;
          color: #333333;
          text-overflow: ellipsis;
          word-break: break-all;
          display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
          -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
          -webkit-line-clamp: 2; /** 显示的行数 **/
          overflow: hidden; /** 隐藏超出的内容 **/
        }
      }
    }
    .rt-previews {
      width: 540px;
      height: 348px;
      border: 20px solid #efefef;
      .notice-banner-pic {
        background-size: 100% 100%;
      }
      ::v-deep .ivu-carousel-dots {
        display: none;
        text-align: right;
        bottom: 25px;
        right: 19px;
        .ivu-carousel-active {
          button {
            background: #fff;
          }
        }
        button {
          width: 8px;
          height: 8px;
          color: #efefef;
        }
      }
    }
  }
  .btn-wrap {
    width: 1200px;
    margin: 0px auto;
    text-align: center;
    ::v-deep .ivu-btn {
      width: 150px;
      height: 38px;
      line-height: 38px;
      border-radius: 19px;
      font-size: 16px;
      color: #2ccaa9;
      border-color: #2ccaa9;
      i {
        margin-left: 6px;
      }
    }
  }
}
</style>
