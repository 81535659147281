<template>
  <div class="main-system">
    <div class="more-wrap">
      <div class="item">
        <img :src="homeTxtImg" alt="" />
        <span class="label">文本示范</span>
        <span class="remark">招标文件、资审文件、投标文件示范文本、</span>
        <el-button
          type="primary"
          ghost
          @click="goHelpCenterList"
          data-aos="fade-down"
          data-aos-easing="ease"
          data-aos-offset="0"
          data-aos-delay="150"
          >了解更多</el-button
        >
      </div>
      <div class="item">
        <img :src="homeLawerImg" alt="" />
        <span class="label">政策法规</span>
        <span class="remark">招标投标相关法律法规</span>
        <el-button
          type="primary"
          ghost
          @click="goPolicyFileList"
          data-aos="fade-down"
          data-aos-easing="ease"
          data-aos-offset="0"
          data-aos-delay="150"
          >了解更多</el-button
        >
      </div>
      <div class="item">
        <img :src="homeDownloadImg" alt="" />
        <span class="label">下载中心</span>
        <span class="remark">CA驱动和投标客户端</span>
        <el-button
          type="primary"
          ghost
          @click="goDownloadList"
          data-aos="fade-down"
          data-aos-easing="ease"
          data-aos-offset="0"
          data-aos-delay="150"
          >了解更多</el-button
        >
      </div>
    </div>
    <div
      class="title-wrap"
      data-aos="fade-up"
      data-aos-easing="ease"
      data-aos-offset="0"
      data-aos-delay="200"
    >
      <span class="txt">系统指引</span>
      <span class="underline"></span>
    </div>
    <div
      class="banner-wrap"
      data-aos="fade-up"
      data-aos-easing="ease"
      data-aos-offset="0"
      data-aos-delay="200"
    >
      <Carousel :value="0" dots="none" arrow="always">
        <CarouselItem v-for="(item, i) in systemPointBannerList" :key="i">
          <img class="system-banner-pic" :src="item.pic" />
        </CarouselItem>
      </Carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainSystem',
  data() {
    return {
      homeTxtImg: require('../image/home-txt@2x.png'), // 系统指引了解更多图片
      homeLawerImg: require('../image/home-lawer@2x.png'), // 系统指引了解更多图片
      homeDownloadImg: require('../image/home-download@2x.png'), // 系统指引了解更多图片
      systemPointBannerList: [
        {
          pic: require('../image/system-point-banner1.png'),
        },
        {
          pic: require('../image/system-point-banner2.png'),
        },
        {
          pic: require('../image/system-point-banner3.png'),
        },
      ],
    }
  },

  mounted() {},

  methods: {
    goHelpCenterList() {
      this.$router.push({
        path: '/helpCenter',
        query: { name: '帮助中心' },
      })
    },
    goPolicyFileList() {
      this.$router.push({
        path: '/policyDocument',
        query: { name: '政策文件' },
      })
    },
    goDownloadList() {
      this.$router.push({
        path: '/download',
        query: { name: '下载中心' },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.main-system {
  width: 100%;
  min-width: 1200px;
  background: #f8f9fd;
  padding: 60px 0px 80px;
  .more-wrap {
    width: 1200px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    .item {
      width: 374px;
      height: 335px;
      border-radius: 8px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 39px;
      &:hover {
        box-shadow: 0px 3px 6px rgba(56,96,244,0.5);
      }
      &:last-child {
        margin-right: 0px;
      }
      img {
        margin-top: 60px;
        width: 60px;
        height: 60px;
      }
      .label {
        margin: 16px 0px;
        font-size: 30px;
        font-weight: bold;
        line-height: 42px;
        color: #333333;
      }
      .remark {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        color: #777777;
      }
      button {
        font-size: 18px;
        margin-top: 50px;
        color: #fff;
        width: 116px;
        height: 42px;
        background-color: #4184f4;
        border-color: #4184f4;
        border-radius: 8px;
        &:hover {
          background-color: #57a3f3;
        }
      }
    }
  }
  .title-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    .txt {
      font-size: 30px;
      color: #333;
      font-weight: bold;
      height: 42px;
      line-height: 42px;
    }
    .underline {
      margin-top: 5px;
      margin-bottom: 40px;
      width: 60px;
      height: 2px;
      background-color: #4184f4;
    }
  }
  .banner-wrap {
    width: 1200px;
    margin: 0px auto;
    height: 460px;
    ::v-deep .ivu-carousel {
      height: 100%;
      .ivu-carousel-arrow {
        &.left {
          left: 0px;
          border-radius: 0%;
          width: 34px;
          height: 80px;
          background: #d9e6fd;
          i:before {
            content: '';
            display: inline-block;
            width: 0px;
            height: 0px;
            border-top: 8px solid transparent;
            border-right: 12px solid #fff;
            border-bottom: 8px solid transparent;
          }
        }
        &.right {
          right: 0px;
          border-radius: 0%;
          width: 34px;
          height: 80px;
          background: #d9e6fd;
          i:before {
            content: '';
            display: inline-block;
            width: 0px;
            height: 0px;
            border-top: 8px solid transparent;
            border-left: 12px solid #fff;
            border-bottom: 8px solid transparent;
          }
        }
      }
    }
  }
}
</style>
