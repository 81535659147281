<template>
  <div class="main-announcement">
    <div
      class="announcement-wrapper"
      data-aos="fade-up"
      data-aos-easing="ease"
      data-aos-offset="0"
      data-aos-delay="200"
    >
      <div class="left-announce">
        <div class="prequalification">公开招标-资格预审</div>
        <div class="content-one">
          <div class="lt-prequalification">
            <span @click="goProjectAnnouncement('0', '1', '0')"
              >资格预审公告</span
            >
            <span @click="goProjectAnnouncement('0', '1', '10')">评标公示</span>
            <span @click="goProjectAnnouncement('0', '1', '3')"
              >招标异常报告</span
            >
          </div>
          <div class="rt-prequalification">
            <span @click="goProjectAnnouncement('0', '1', '5')"
              >资格预审结果公告</span
            >
            <span @click="goProjectAnnouncement('0', '1', '6')"
              >中标结果公告</span
            >
            <span @click="goProjectAnnouncement('0', '1', '2')">澄清公告</span>
          </div>
        </div>
        <div class="prequalification">公开招标-资格后审</div>
        <div class="content-one">
          <div class="lt-prequalification">
            <span @click="goProjectAnnouncement('0', '2', '1')">招标公告</span>
            <span @click="goProjectAnnouncement('0', '2', '6')"
              >中标结果公告</span
            >
            <span @click="goProjectAnnouncement('0', '2', '3')"
              >招标异常报告</span
            >
          </div>
          <div class="rt-prequalification">
            <span @click="goProjectAnnouncement('0', '2', '10')">评标公示</span>
            <span @click="goProjectAnnouncement('0', '2', '2')">澄清公告</span>
          </div>
        </div>
        <div class="prequalification">邀请招标</div>
        <div class="content-one">
          <div class="lt-prequalification">
            <span @click="goProjectAnnouncement('1', '', '10')">评标公示</span>
            <span @click="goProjectAnnouncement('1', '', '2')">澄清公告</span>
          </div>
          <div class="rt-prequalification">
            <span @click="goProjectAnnouncement('1', '', '6')"
              >中标结果公告</span
            >
            <span @click="goProjectAnnouncement('1', '', '3')"
              >招标异常报告</span
            >
          </div>
        </div>
      </div>
      <div class="center-announce">
        <div class="latestAnnouncement">
          <span class="new">最新公告</span>
          <span class="more" @click="() => goProjectAnnouncement()"
            >更多<Icon size="14" type="ios-arrow-forward"
          /></span>
        </div>
        <ul>
          <li
            v-for="(announce, ind) in announcementDataList"
            :key="ind"
            @click="goAnnouncementDetal(announce)"
          >
            <b class="point"></b>
            <span class="txt-wrap">
              <span class="new-notice-txt">{{ announce.noticeName }}</span>
              <img v-if="announce.isNew" :src="newsImg" alt="" />
            </span>
            <span class="time">{{ announce.releaseTime }}</span>
          </li>
        </ul>
      </div>
      <div class="right-announce">
        <div class="column-line"></div>
        <div class="row-wrap">
          <div class="one-item" @click="goAuthLogin(1)">
            <div class="top">
              <img :src="announceRt1Img" alt="" />
              <!-- <c-icon name="icon-wuyeguanlixitong"></c-icon> -->
            </div>
            <div class="text">物业管理系统</div>
          </div>
          <div class="one-item" @click="goAuthLogin(2)">
            <div class="top">
              <img :src="announceRt2Img" alt="" />
              <!-- <c-icon name="icon-zhaobiaodailidenglu"></c-icon> -->
            </div>
            <div class="text">招标代理登录</div>
          </div>
        </div>
        <div class="row-line"></div>
        <div class="row-wrap">
          <div class="one-item" @click="goAuthLogin(3)">
            <div class="top">
              <img :src="announceRt3Img" alt="" />
              <!-- <c-icon name="icon-zhaobiaorendenglu"></c-icon> -->
            </div>
            <div class="text">招标人登录</div>
          </div>
          <div class="one-item" @click="goAuthLogin(4)">
            <div class="top">
              <img :src="announceRt4Img" alt="" />
              <!-- <c-icon name="icon-toubiaorendenglu"></c-icon> -->
            </div>
            <div class="text">投标人登录</div>
          </div>
        </div>
        <div class="row-line"></div>
        <div class="row-wrap">
          <div class="one-item" @click="goAuthLogin(5)">
            <div class="top">
              <img :src="announceRt5Img" alt="" />
              <!-- <c-icon name="icon-zhuanjiadenglu"></c-icon> -->
            </div>
            <div class="text">专家登录</div>
          </div>
          <div class="one-item" @click="goAuthLogin(6)">
            <div class="top">
              <img :src="announceRt6Img" alt="" />
              <!-- <c-icon name="icon-jianguanrenyuandenglu"></c-icon> -->
            </div>
            <div class="text">监管人员登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetProjectNoticeLatest } from '@/axios/api/home.js'
import { isIEBrowser } from '@/utils/browser.js'
import { ie_text_ellipsis } from '@/utils/compatibilityIE.js'
import moment from 'moment'
import { getBasePrefixUrl } from '@/utils/envBaseUrl.js'

export default {
  name: 'MainAnnouncement',
  data() {
    return {
      newsImg: require('../image/news@2x.png'), // news图
      announceRt1Img: require('../image/announce-rt-1@2x.png'), // 公告右边图
      announceRt2Img: require('../image/announce-rt-2@2x.png'), // 公告右边图
      announceRt3Img: require('../image/announce-rt-3@2x.png'), // 公告右边图
      announceRt4Img: require('../image/announce-rt-4@2x.png'), // 公告右边图
      announceRt5Img: require('../image/announce-rt-5@2x.png'), // 公告右边图
      announceRt6Img: require('../image/announce-rt-6@2x.png'), // 公告右边图
      announcementDataList: [], // 最新公告信息
    }
  },
  async mounted() {
    await this.getNewsAnnounceList()
    // 兼容IE
    this.compatibleIE()
  },
  methods: {
    goAuthLogin(type){
      console.log('type=',type)
      if(type == '1'){
        // window.location.href = `http://119.97.201.28:4000/test/oauth2/authorize?appid=&response_type=code&scope=snsapi_userinfo&state=1`
        window.location.href = `https://www.whwgzx.com:8188/`
      }else if(type == '2'){
        this.$router.push({path: '/login'})
      }else {
        if(localStorage.getItem('token')) {
            window.location.href = getBasePrefixUrl() + "trade/czy/matter/deal"
        } else {
            let redirectUrl = getBasePrefixUrl() + "trade/login"
            // window.location.href = `http://119.97.201.28:4000/test/oauth2/authorize?appid=&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=1`
            window.location.href = `https://www.whwgzx.com:8188/login?appid=&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=1`
        }
      }
    },
    async getNewsAnnounceList() {
      let res = await GetProjectNoticeLatest({ projectNoticeNo: 10 })
      res.forEach((one) => {
        const nowStr = moment(new Date()).format('YYYY-MM-DD')
        if (one.releaseTime === nowStr) {
          one.isNew = true
        }
      })
      this.announcementDataList = res || []
    },
    // 兼容IE
    compatibleIE() {
      // 兼容ie，文字超行省略...
      if (isIEBrowser()) {
        ie_text_ellipsis(this, 'new-notice-txt', 22)
      }
    },
    goProjectAnnouncement(
      biderWay = '',
      qualificationType = '',
      noticeType = ''
    ) {
      this.$router.push({
        path: '/announcement',
        query: { name: '项目公告', biderWay, qualificationType, noticeType },
      })
    },
    goAnnouncementDetal(row = {}) {
      this.$router.push({
        path: '/announcement-info',
        query: { projectNoticeId: row.projectNoticeId },
      })
    },
  },
}
</script>
<style lang="less" scoped>
.main-announcement {
  width: 100%;
  min-width: 1200px;
  background: #f8f9fd;
  padding: 80px 0px;
  .announcement-wrapper {
    width: 1200px;
    margin: 0px auto;
    height: 442px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    .left-announce {
      height: 100%;
      flex: 0 0 310px;
      background-color: #fff;
      background-image: url('../image/horn@2x.png');
      background-repeat: no-repeat;
      background-size: 130px 130px;
      background-position: 130px 287px;
      padding: 0px 20px;
      position: relative;
      .prequalification {
        height: 42px;
        line-height: 42px;
        font-weight: bold;
        color: #333333;
        font-size: 16px;
        border-bottom: 1px dashed #cccccc;
        box-sizing: border-box;
      }
      .content-one {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .lt-prequalification,
        .rt-prequalification {
          margin: 10px 0px;
          width: 50%;
          display: flex;
          flex-direction: column;
          span {
            font-weight: 500;
            line-height: 22px;
            font-size: 16px;
            color: #666666;
            margin-bottom: 10px;
            cursor: pointer;
            &:hover {
              color: #4184f4;
            }
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
    .center-announce {
      height: 100%;
      flex: 1 1 auto;
      background: #fff;
      margin: 0px 20px;
      .latestAnnouncement {
        padding: 0px 20px;
        height: 42px;
        line-height: 42px;
        color: #666;
        font-size: 16px;
        border-bottom: 2px solid #4184f4;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .new {
          font-weight: bold;
          color: #4184f4;
        }
        .more {
          font-weight: 400;
          cursor: pointer;
          i {
            color: #666;
          }
          &:hover {
            color: #4184f4;
            i {
              color: #4184f4;
            }
          }
        }
      }
      ul {
        height: calc(100% - 42px);
        overflow-y: auto;
        li {
          position: relative;
          height: 40px;
          line-height: 40px;
          padding: 0px 20px;
          color: #333;
          font-size: 16px;
          font-weight: 400;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          .point {
            position: absolute;
            width: 2px;
            height: 2px;
            display: inline-block;
            border-radius: 50%;
            background: #333;
            vertical-align: middle;
          }
          &:hover {
            background-color: #e5eeff;
          }
          .txt-wrap {
            width: 360px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .new-notice-txt {
              max-width: calc(100% - 30px);
              word-break: break-all;
              display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
              -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
              -webkit-line-clamp: 1; /** 显示的行数 **/
              overflow: hidden; /** 隐藏超出的内容 **/
            }
            img {
              width: 22px;
              height: 10px;
              margin-left: 8px;
            }
          }
          .time {
            font-size: 14px;
            color: #999;
          }
        }
      }
    }
    .right-announce {
      height: 100%;
      flex: 0 0 310px;
      background: #fff;
      position: relative;
      .row-wrap {
        width: 100%;
        display: flex;
        flex-direction: row;
        .one-item {
          width: calc(50% - 1px);
          height: 146px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 31px;
          cursor: pointer;
          &:hover {
            box-shadow: 0px 3px 6px rgba(0, 93, 255, 0.5);
            .text {
              color: #4184f4;
            }
          }
          .top {
            width: 56px;
            height: 56px;
            line-height: 56px;
            border-radius: 50%;
            background-color: #4184f4;
            text-align: center;
            img {
              width: 34px;
              height: 34px;
            }
            i {
              font-size: 34px;
            }
          }
          .text {
            margin-top: 9px;
            font-size: 15px;
            font-weight: 400;
            color: #666666;
          }
        }
      }
      .column-line {
        position: absolute;
        top: 10px;
        left: calc(50% - 1px);
        width: 1px;
        height: calc(100% - 20px);
        background-color: #cccccc;
      }
      .row-line {
        margin-left: 10px;
        height: 1px;
        width: calc(100% - 20px);
        background-color: #cccccc;
      }
    }
  }
}
</style>
