<template>
  <div class="announcement-info-container">
    <div class="breadcrumb-info">当前位置 > 项目公告</div>
    <div class="top-wrap">
      <span class="title">{{ info.projectName }}</span>
      <span class="code">{{ info.projectCode }}</span>
      <div class="progress-wrap">
        <div class="step-wrap" v-if="info.biderWay == '0'">
          <span
            class="step"
            :class="[getActiveClass(1, info.bidNoticeList)]"
            @click="handleClickStep(1, info.bidNoticeList)"
            ><b>1</b><i class="el-icon-check"></i
            ><span>预审/招标公告</span></span
          >
          <span class="line-1px" :class="[getActiveLineClass(1)]"></span>
          <span
            class="step"
            :class="[getActiveClass(2, info.clarifyNoticeList)]"
            @click="handleClickStep(2, info.clarifyNoticeList)"
            ><b>2</b><i class="el-icon-check"></i><span>澄清公告</span></span
          >
          <span class="line-1px" :class="[getActiveLineClass(2)]"></span>
          <span
            class="step"
            :class="[getActiveClass(3, info.reviewNoticeList)]"
            @click="handleClickStep(3, info.reviewNoticeList)"
            ><b>3</b><i class="el-icon-check"></i><span>评标公示</span></span
          >
          <span class="line-1px" :class="[getActiveLineClass(3)]"></span>
          <span
            class="step"
            :class="[getActiveClass(4, info.winBidNoticeList)]"
            @click="handleClickStep(4, info.winBidNoticeList)"
            ><b>4</b><i class="el-icon-check"></i
            ><span>中标结果公告</span></span
          >
          <span v-if="info.terminationNoticeList.length > 0" class="line-1px" :class="[getActiveLineClass(4)]"></span>
          <span
            v-if="info.terminationNoticeList.length > 0"
            class="step"
            :class="[getActiveClass(5, info.terminationNoticeList)]"
            @click="handleClickStep(5, info.terminationNoticeList)"
            ><b>5</b><i class="el-icon-check"></i><span>终止公告</span></span
          >
        </div>
        <div class="step-wrap" v-else>
          <span
              class="step"
              :class="[getActiveClass(1, info.clarifyNoticeList)]"
              @click="handleClickStep(1, info.clarifyNoticeList)"
          ><b>1</b><i class="el-icon-check"></i><span>澄清公告</span></span
          >
          <span class="line-1px" :class="[getActiveLineClass(1)]"></span>
          <span
            class="step"
            :class="[getActiveClass(2, info.reviewNoticeList)]"
            @click="handleClickStep(2, info.reviewNoticeList)"
            ><b>2</b><i class="el-icon-check"></i><span>评标公示</span></span
          >
          <span class="line-1px" :class="[getActiveLineClass(2)]"></span>
          <span
            class="step"
            :class="[getActiveClass(3, info.winBidNoticeList)]"
            @click="handleClickStep(3, info.winBidNoticeList)"
            ><b>3</b><i class="el-icon-check"></i
            ><span>中标结果公告</span></span
          >
          <span v-if="info.terminationNoticeList.length > 0" class="line-1px" :class="[getActiveLineClass(3)]"></span>
          <span
            v-if="info.terminationNoticeList.length > 0"
            class="step"
            :class="[getActiveClass(4, info.terminationNoticeList)]"
            @click="handleClickStep(4, info.terminationNoticeList)"
            ><b>4</b><i class="el-icon-check"></i><span>终止公告</span></span
          >
        </div>
      </div>
    </div>
    <div class="list-wrap">
      <div class="notice-list" v-if="noticeList && noticeList.length > 1">
        <div
          class="item"
          v-for="(notice, ind) in noticeList"
          :key="ind"
          @click="handleClickOneNotice(notice)"
        >
          <span class="notice-txt">{{ notice.noticeName }}</span>
          <span class="time">{{ notice.releaseTime }}</span>
        </div>
      </div>
    </div>
    <div class="content-wrap">
      <div class="title">
        {{ info.noticeName }}
      </div>
      <div class="publish-time">发布时间：{{ info.releaseTime }}</div>
      <div class="line-1px"></div>
      <div class="content" v-html="info.noticeContent"></div>
    </div>
  </div>
</template>

<script>
import { GetProjectNoticeDetail } from '@/axios/api/home.js'
import { isIEBrowser } from '@/utils/browser.js'
import { ie_text_ellipsis } from '@/utils/compatibilityIE.js'
import moment from 'moment'

export default {
  name: 'AnnouncementInfo',

  data() {
    return {
      curStep: 0,
      info: {
        biderWay: '',
        qualificationType: '',
        noticeName: '',
        releaseTime: '',
        noticeContent: '',
        bidNoticeList: [], //预审/招标公告
        clarifyNoticeList: [], //澄清公告
        reviewNoticeList: [], //评标公告
        winBidNoticeList: [], //中标公告
        terminationNoticeList: [], //终止公告
      },
      noticeList: [], // 公告栏信息集合
      noticeInfo: {}, //公告信息
      projectNoticeId: null,
    }
  },

  async mounted() {
    this.projectNoticeId = this.$route.query.projectNoticeId || null
    await this.getDetail()
    // 兼容IE
    this.compatibleIE()
  },

  methods: {
    async getDetail() {
      const res = await GetProjectNoticeDetail({
        projectNoticeId: Number(this.projectNoticeId),
      })
      res.releaseTime = res.releaseTime?moment(res.releaseTime).format('YYYY-MM-DD'):''
      // 设置读取步骤条
      this.handleSetStep(res)
      this.info = res
    },
    handleSetStep(res) {
      //公告类型;0:预审公告 1:招标公告 2:澄清公告 3:异常公告 5:资格预审结果公告
      // 10评标公示 6:中标公告 7:邀请函(邀请函是没有预审的，等同于招标)
      if(res.biderWay == '0'){
        if (['0', '1', '7'].includes(res.noticeType)) {
          this.curStep = 1
          this.noticeList = res.bidNoticeList || []
        } else if (['2'].includes(res.noticeType)) {
          this.curStep = 2
          this.noticeList = res.clarifyNoticeList || []
        } else if (['5','10'].includes(res.noticeType)) {
          this.curStep = 3
          this.noticeList = res.reviewNoticeList || []
        } else if (['6'].includes(res.noticeType)) {
          this.curStep = 4
          this.noticeList = res.winBidNoticeList || []
        } else if (['3'].includes(res.noticeType)) {
          this.curStep = 5
          this.noticeList = res.terminationNoticeList || []
        }
      }else {
        if (['2'].includes(res.noticeType)) {
          this.curStep = 1
          this.noticeList = res.clarifyNoticeList || []
        } else if (['10'].includes(res.noticeType)) {
          this.curStep = 2
          this.noticeList = res.reviewNoticeList || []
        } else if (['6'].includes(res.noticeType)) {
          this.curStep = 3
          this.noticeList = res.winBidNoticeList || []
        } else if (['3'].includes(res.noticeType)) {
          this.curStep = 4
          this.noticeList = res.terminationNoticeList || []
        }
      }
      this.noticeInfo = this.noticeList[0] || {}
    },
    // 兼容IE
    compatibleIE() {
      // 兼容ie，文字超行省略...
      if (isIEBrowser()) {
        ie_text_ellipsis(this, 'notice-txt', 48)
      }
    },
    async handleClickOneNotice(notice) {
      this.projectNoticeId = notice.projectNoticeId
      await this.getDetail()
    },
    async handleClickStep(step, list) {
      if (this.curStep == step) return
      if (list && list.length > 0) {
        this.curStep = step
        this.noticeList = list
        this.noticeInfo = this.noticeList[0] || {}
        this.projectNoticeId = this.noticeInfo.projectNoticeId
        await this.getDetail()
      }
    },
    getActiveClass(step, list) {
      if (this.curStep == step) {
        return 'active2'
      } else {
        if (list && list.length > 0) {
          return 'active1'
        }
      }
      return 'active3'
    },
    getActiveLineClass(step) {
      if (this.curStep > step) {
        return 'active1'
      }
      return ''
    },
  },
}
</script>

<style lang="less" scoped>
.announcement-info-container {
  background: #f8f9fd;
  padding-bottom: 80px;
  .breadcrumb-info {
    width: 1200px;
    height: 66px;
    line-height: 66px !important;
    margin: 0px auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #333333;
  }
  .top-wrap {
    width: 1200px;
    height: 214px;
    margin: 0px auto;
    padding: 60px 0px;
    background: #4184f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .title {
      height: 33px;
      font-size: 24px;
      font-weight: bold;
      line-height: 33px;
      color: #ffffff;
      margin-bottom: 10px;
    }
    .code {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #ffffff;
    }
    .progress-wrap {
      position: absolute;
      top: 144px;
      left: 155px;
      z-index: 1;
      background-color: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      width: 891px;
      height: 164px;
      display: flex;
      justify-content: center;
      padding-top: 44px;
      .step-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        .step {
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border: 2px solid #999999;
          border-radius: 50%;
          font-size: 16px;
          font-weight: bold;
          color: #999999;
          cursor: pointer;
          position: relative;
          i {
            font-size: 20px;
            font-weight: bold;
            display: none;
          }
          &.active1 {
            color: #4184f4;
            border-color: #4184f4;
            span {
              color: #333;
            }
          }
          &.active2 {
            color: #fff;
            background-color: #4184f4;
            border-color: #4184f4;
            b {
              display: none;
            }
            i {
              display: inline-block;
            }
            span {
              color: #333;
            }
          }
          &.active3 {
            cursor: auto;
          }
          span {
            position: absolute;
            top: 64px;
            left: -30px;
            width: 100px;
            height: 21px;
            text-align: center;
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
            color: #999999;
          }
        }
        .line-1px {
          height: 1px;
          width: 102px;
          box-sizing: border-box;
          border-bottom: 1px dashed #999999;
          background-color: #cccccc;
          &.active1 {
            border-bottom: 1px solid #4184f4;
          }
        }
      }
    }
  }
  .list-wrap {
    width: 1200px;
    margin: 0px auto;
    padding: 124px 0px 40px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .notice-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .item {
      width: 891px;
      height: 52px;
      line-height: 52px;
      display: flex;
      cursor: pointer;
      color: #666666;
      .notice-txt {
        width: 731px;
        padding-left: 30px;
        word-break: break-all;
        display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
        -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
        -webkit-line-clamp: 1; /** 显示的行数 **/
        overflow: hidden; /** 隐藏超出的内容 **/
      }
      .time {
        width: 160px;
        text-align: center;
      }
      &:hover {
        background-color: #e3f0ff !important;
        color: #4184f4;
      }
      &:nth-of-type(2n) {
        background-color: #f7f7f7;
      }
    }
  }
  .content-wrap {
    width: 1200px;
    margin: 30px auto 0px;
    padding: 40px 175px 40px 134px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    .title {
      margin: 0px auto;
      width: 648px;
      font-size: 24px;
      font-weight: bold;
      line-height: 33px;
      text-align: center;
      color: #333333;
    }
    .publish-time {
      margin: 10px auto 0px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #999999;
    }
    .line-1px {
      margin-top: 21px;
      height: 1px;
      width: 100%;
      background-color: #ccc;
    }
    .content {
      margin-top: 20px;
      p {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
