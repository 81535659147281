<template>
  <div class="announcement-container">
    <div class="breadcrumb-info">当前位置 > {{ $route.query.name }}</div>
    <div class="content-wrap">
      <div class="search-input-wrap">
        <el-input v-model="noticeName" placeholder="请输入公告名称"></el-input>
        <el-button type="primary" @click="getDataList"
          ><Icon type="md-search" />搜索</el-button
        >
      </div>
      <div class="search-label-wrap">
        <div class="block">
          <span class="label">招标方式</span>
          <span class="wrap">
            <span
              class="all"
              :class="{ active: biderWay === '' }"
              @click="handleClickBidWay('')"
              >全部</span
            >
            <span
              class="val"
              :class="{ active: biderWay === '0' }"
              @click="handleClickBidWay('0')"
              >公开招标</span
            >
            <span
              class="val"
              :class="{ active: biderWay === '1' }"
              @click="handleClickBidWay('1')"
              >邀请招标</span
            >
          </span>
        </div>
        <div class="block" v-if="biderWay != '1'">
          <span class="label">资审方式</span>
          <span class="wrap">
            <span
              class="all"
              :class="{ active: qualificationType === '' }"
              @click="handleClickQualificationType('')"
              >全部</span
            >
            <span
              class="val"
              :class="{ active: qualificationType === '1' }"
              @click="handleClickQualificationType('1')"
              >资格预审</span
            >
            <span
              class="val"
              :class="{ active: qualificationType === '2' }"
              @click="handleClickQualificationType('2')"
              >资格后审</span
            >
          </span>
        </div>
        <div class="block">
          <span class="label">公告类型</span>
          <span class="wrap">
            <span
              class="all"
              :class="{ active: noticeType === '' }"
              @click="handleClickNoticeType('')"
              >全部</span
            >
            <span
              v-for="(notice, ind) in noticeTypeList"
              :key="ind"
              class="val"
              :class="{ active: noticeType === notice.value }"
              @click="handleClickNoticeType(notice.value)"
              >{{ notice.label }}</span
            >
          </span>
        </div>
        <div class="block">
          <span class="label">发布时间</span>
          <span class="wrap space-between">
            <span class="left-wrap">
              <span
                class="all"
                :class="{ active: releaseTimeType === null }"
                @click="handleClickReleaseTimeType(null)"
                >全部</span
              >
              <span
                class="val"
                :class="{ active: releaseTimeType === 1 }"
                @click="handleClickReleaseTimeType(1)"
                >近三天</span
              >
              <span
                class="val"
                :class="{ active: releaseTimeType === 2 }"
                @click="handleClickReleaseTimeType(2)"
                >近一周</span
              >
              <span
                class="val"
                :class="{ active: releaseTimeType === 3 }"
                @click="handleClickReleaseTimeType(3)"
                >近一个月</span
              >
            </span>
            <span class="time-wrap">
              <DatePickerIview
                type="daterange"
                v-model="releaseTimeRange"
                value-format="yyyy-MM-dd"
                confirm
                split-panels
                placeholder="选择发布时间范围"
                style="width: 260px;"
                :open="openTimeRange"
                @on-change="handleReleaseTimeChange"
                @on-clear="handleReleaseTimeClear"
                @on-ok="handleReleaseTimeOk"
              >
                <div
                  class="publish-time-wrap"
                  @click="() => (openTimeRange = !openTimeRange)"
                >
                  <template v-if="releaseTimeRange.join('~') == '~'"
                    >选择发布时间范围</template
                  >
                  <template v-else>{{ releaseTimeRange.join('~') }}</template>
                  <c-icon slot="prefix" name="icon-rili"></c-icon>
                </div>
              </DatePickerIview>
              <Button glost @click="getDataList">确定</Button>
            </span>
          </span>
        </div>
      </div>
      <div class="table-content">
        <Table border :columns="columnsTable" :data="dataList">
          <template #noticeName="{row}">
            <span class="code-link" @click="goDetal(row)">{{
              row.noticeName
            }}</span>
          </template>
        </Table>
        <Page
          :total="page.total"
          show-elevator
          show-sizer
          show-total
          :current="page.pageIndex"
          class="pageClass"
          :page-size-opts="[10, 20, 30, 50]"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { GetProjectNoticePage } from '@/axios/api/home.js'
import { DatePicker as DatePickerIview } from 'view-design'
// import moment from 'moment'

export default {
  name: 'Announcement',
  components: {
    DatePickerIview,
  },
  data() {
    return {
      noticeName: '',
      biderWay: '', // 招标方式
      qualificationType: '', // 资审方式
      noticeType: '', // 公告类型
      releaseTimeType: null, // 发布时间 1-近三天 2-近一周 3-近一个月
      releaseTimeRange: ['', ''], // 发布时间范围
      dataList: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      columnsTable: [
        {
          title: '项目编号',
          key: 'projectCode',
          minWidth: 100,
        },
        {
          title: '公告名称',
          key: 'noticeName',
          slot: 'noticeName',
          minWidth: 450,
        },
        {
          title: '公告发布时间',
          key: 'releaseTime',
          minWidth: 100,
        },
      ],
      openTimeRange: false,
    }
  },
  computed: {
    noticeTypeList() {
      // 公告类型;0:预审公告 1:招标公告 2:澄清公告 3:异常公告 5资审结果公告 6:中标公告  7:邀请函(邀请函是没有预审的，等同于招标)10评标公示
      const list = [
        {
          label: '资格预审公告',
          value: '0',
        },
        {
          label: '招标公告',
          value: '1',
        },
        {
          label: '资格预审结果公告',
          // value: '4',
          value: '5',
        },
        {
          label: '评标公示',
          // value: '5',
          value: '10',
        },
        {
          label: '中标结果公告',
          value: '6',
        },
        {
          label: '澄清公告',
          value: '2',
        },
        {
          label: '终止公告',
          value: '3',
        },
      ]
      if (this.biderWay != '') {
        if (this.biderWay == '0') {
          if (this.qualificationType != '') {
            if (this.qualificationType == '1') {
              list.splice(1, 1)
            } else {
              list.splice(0, 1)
              list.splice(1, 1)
            }
          }
        } else {
          list.splice(0, 3)
        }
      } else {
        if (this.qualificationType != '') {
          if (this.qualificationType == '1') {
            list.splice(1, 1)
          } else {
            list.splice(0, 1)
            list.splice(1, 1)
          }
        }
      }
      return list
    },
  },
  mounted() {
    this.biderWay = this.$route.query.biderWay || ''
    this.qualificationType = this.$route.query.qualificationType || ''
    this.noticeType = this.$route.query.noticeType || ''
    this.getDataList()
  },
  methods: {
    handleReleaseTimeChange(date) {
      this.releaseTimeRange = date
    },
    handleReleaseTimeClear() {
      this.openTimeRange = false
    },
    handleReleaseTimeOk() {
      this.openTimeRange = false
    },
    handleClickBidWay(val) {
      this.biderWay = val
      if(val === '0') {
          this.qualificationType = '1'
          this.noticeType = '0'
      }
      if(val === '1') {
          this.qualificationType = '2'
          this.noticeType = '10'
      }
      this.getDataList()
    },
    handleClickQualificationType(val) {
      this.qualificationType = val
      if(val === '2') {
          this.noticeType = '1'
      }
      this.getDataList()
    },
    handleClickNoticeType(val) {
      this.noticeType = val
      this.getDataList()
    },
    handleClickReleaseTimeType(val) {
      this.releaseTimeType = val
      this.getDataList()
    },
    async getDataList() {
      const param = {
        noticeName: this.noticeName,
        biderWay: this.biderWay || null,
        qualificationType: this.qualificationType || null,
        noticeType: this.noticeType || null,
        releaseTimeType: this.releaseTimeType,
        // releaseStartTime: this.releaseTimeRange[0]
        //   ? moment(this.releaseTimeRange[0] + ' 00:00:00')
        //   : null,
        // releaseEndTime: this.releaseTimeRange[1]
        //   ? moment(this.releaseTimeRange[1] + ' 23:59:59')
        //   : null,
        page: this.page.pageIndex,
        pageSize: this.page.pageSize,
      }
      let strt1 = ''
      let strt2 = ''
      if(this.releaseTimeRange[0]) {
          let t1 = new Date(this.releaseTimeRange[0])
          t1.setHours(0, 0, 0, 0)
          strt1 = util.dateFormat('YYYY-mm-dd HH:MM:SS', t1)
      }
      if(this.releaseTimeRange[1]) {
          let t2 = new Date(this.releaseTimeRange[1])
          t2.setHours(23, 59, 59, 0)
          strt2 = util.dateFormat('YYYY-mm-dd HH:MM:SS', t2)
      }
      param.releaseStartTime = strt1
      param.releaseEndTime = strt2
      const res = await GetProjectNoticePage(param)
      this.dataList = res.list || []
      this.page.total = +res.total
    },

    changePage(pageIndex) {
      this.page.pageIndex = pageIndex
      this.getDataList()
    },
    changePageSize(pageSize) {
      this.page.pageSize = pageSize
      this.getDataList()
    },
    goDetal(row) {
      this.$router.push({
        path: '/announcement-info',
        query: { projectNoticeId: row.projectNoticeId },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.announcement-container {
  background: #f8f9fd;
  padding-bottom: 80px;
  .breadcrumb-info {
    width: 1200px;
    height: 66px;
    line-height: 66px !important;
    margin: 0px auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #333333;
  }
  .content-wrap {
    width: 1200px;
    margin: 0px auto;
    padding: 60px 80px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    .search-input-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      ::v-deep .el-input {
        width: 890px;
        height: 40px;
      }
      button {
        width: 120px;
        height: 40px;
        background: #4184f4;
        border-color: #4184f4;
        &:hover {
          background-color: #57a3f3;
        }
        i {
          font-size: 16px;
          margin-right: 6px;
        }
      }
    }
    .search-label-wrap {
      margin: 40px 0px;
      font-size: 16px;
      .block {
        height: 52px;
        display: flex;
        align-items: center;
        .label {
          width: 160px;
          height: 52px;
          line-height: 52px !important;
          text-align: center;
          border: 1px solid #eeeeee;
          box-sizing: border-box;
          background: #f7f7f7;
          //font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 17px;
          color: #666666;
        }
        .wrap {
          height: 52px;
          width: calc(100% - 160px);
          padding: 0px 20px 0px 30px;
          display: flex;
          align-items: center;
          border: 1px solid #eeeeee;
          box-sizing: border-box;
          &.space-between {
            justify-content: space-between;
            .left-wrap {
              display: flex;
              align-items: center;
            }
          }
          .all {
            width: 74px;
            height: 30px;
            line-height: 30px;
            border-radius: 4px;
            text-align: center;
            //font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
            margin-right: 20px;
            cursor: pointer;
            &.active {
              background: #4184f4;
              color: #fff;
            }
          }
          .val {
            padding: 7px 6px 6px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
            margin-right: 12px;
            cursor: pointer;
            &:last-child {
              margin-right: 0px;
            }
            &.active {
              background: #4184f4;
              color: #fff;
            }
          }
          .time-wrap {
            display: flex;
            align-items: center;
            .publish-time-wrap {
              width: 260px;
              height: 32px;
              line-height: 32px;
              padding: 0px 16px;
              border: 1px solid #cccccc;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              i {
                font-size: 20px;
              }
            }
            button {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 7px;
              color: #4184f4;
              width: 74px;
              height: 30px;
              border-color: #005dff;
              margin-left: 20px;
              &:hover {
                background-color: #fff;
                box-shadow: #fff;
              }
            }
          }
        }
      }
    }
  }
  ::v-deep .table-content {

    .ivu-table-border td, .ivu-table-border th {
      border-right: none !important;
    }
    .ivu-table th {
      background-color: #e3f0ff;
      color: #666666;
      font-weight: 400;
      font-size: 16px;
      height: 52px;
    }
    .ivu-table-tbody {
      tr {
        height: 52px;
        .ivu-table-cell {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          .code-link {
            cursor: pointer;
          }
        }
        &:hover {
          .code-link {
            color: #4184f4;
          }
        }
        &:nth-of-type(1n) td {
          background-color: #fff;
        }
        &:nth-of-type(2n) td {
          background-color: #f7f7f7;
        }
      }
    }
    .pageClass {
      margin-top: 50px;
      text-align: right;
      .ivu-page-item-active {
        background-color: #4184f4;
        color: #fff;
      }
    }
  }
}
</style>
