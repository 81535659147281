<template>
  <div class="policyDocument-container">
    <div class="breadcrumb-info">当前位置 > {{ $route.query.name }}</div>
    <div class="module-wrap">
      <!-- 左侧模块菜单 -->
<!--      <LeftMenu></LeftMenu>-->
      <div class="table-content">
        <Table border :columns="columnsTable" :data="dataList">
          <template #titleName="{row}">
            <span class="code-link" @click="goDetal(row)">{{
              row.titleName
            }}</span>
          </template>
        </Table>
        <Page
          :total="page.total"
          show-elevator
          show-sizer
          show-total
          :current="page.pageIndex"
          class="pageClass"
          :page-size-opts="[10, 20, 30, 50]"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { GetPotalNoticePage } from '@/axios/api/home.js'
// import LeftMenu from '@/views/index/left-menu/left-menu.vue'

export default {
  name: 'PolicyDocument',
  components: {
    // LeftMenu,
  },
  data() {
    return {
      dataList: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      columnsTable: [
        {
          title: '政策文件',
          slot: 'titleName',
          key: 'titleName',
          // width: 680,
          width: 940,
        },
        {
          title: '发布日期',
          key: 'releaseDate',
          // width: 183,
          width: 200,
        },
      ],
    }
  },
  computed: {},
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      const param = {
        page: this.page.pageIndex,
        pageSize: this.page.pageSize,
        columnType: 2, //1-重要通知 2-政策文件 3-下载中心 4-帮助中心
        isRelease: 1, // 0-未发布 1-已发布
      }
      const res = await GetPotalNoticePage(param)
      this.dataList = res.list || []
      this.page.total = +res.total
    },
    changePage(pageIndex) {
      this.page.pageIndex = pageIndex
      this.getDataList(pageIndex)
    },
    changePageSize(pageSize) {
      this.page.pageSize = pageSize
      this.getDataList()
    },
    goDetal(row) {
      this.$router.push({
        path: 'policyDocument-info',
        query: { id: row.id },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.policyDocument-container {
  background: #f8f9fd;
  padding-bottom: 80px;
  .breadcrumb-info {
    width: 1200px;
    height: 66px;
    line-height: 66px !important;
    margin: 0px auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #333333;
  }
  .module-wrap {
    width: 1200px;
    min-height: 752px;
    margin: 0px auto;
    display: flex;
    overflow: hidden;
    ::v-deep .table-content {
      flex: 1 1 auto;
      // min-height: 752px;
      background-color: #ffffff;
      padding: 40px 30px 60px;
      .ivu-table-border td, .ivu-table-border th {
        border-right: none !important;
      }
      .ivu-table-wrapper {
        width: 100% ;
      }
      .ivu-table th {
        background-color: #e3f0ff;
        color: #666666;
        font-weight: 400;
        font-size: 16px;
        height: 52px;
      }
      .ivu-table-tbody {
        tr {
          height: 52px;
          .ivu-table-cell {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            .code-link {
              cursor: pointer;
            }
          }
          &:hover {
            .code-link {
              color: #4184f4;
            }
          }
          &:nth-of-type(1n) td {
            background-color: #fff;
          }
          &:nth-of-type(2n) td {
            background-color: #f7f7f7;
          }
        }
      }
      .pageClass {
        margin-top: 50px;
        text-align: right;
        .ivu-page-item-active {
          background-color: #4184f4;
          color: #fff;
        }
      }
    }
  }
}
</style>
